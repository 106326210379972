@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: Josefin Sans, system-ui, sans-serif;
    }

    .drop-target {
        color: transparent !important;
        background: transparent !important;
        position: relative;
    }

    .drop-target::before {
        content: "Drop Here";
        color: hsl(220, 98%, 61%);
        font-size: 0.5em;
        text-transform: uppercase;
        width: 100%;
        height: 100%;
        border: 2px dashed hsl(220, 98%, 61%);
        border-radius: 0.5em;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}